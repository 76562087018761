<template>
    <div>
        <TopNav title="绑定手机" backPath="Mine"></TopNav>
        <div class="tips">
            为了您的账号安全，请绑定手机号
        </div>
        <div class="form-box">
            <div class="input-box-h">
                <input v-model="dataPack.inputPhone" @input="dataPack.inputPhone=dataPack.inputPhone.substr(0,11)" type="number" placeholder="请填写手机号" class="input-h">
            </div>
            <div class="input-box-h">
                <input v-model="dataPack.inputCode" @input="dataPack.inputCode=dataPack.inputCode.substr(0,6)" type="number" placeholder="验证码5分钟内输入有效" class="input-h">
                <div class="get-code-cd" v-if="codecd>0">
                    {{'重新发送('+ codecd+ ')'}}
                </div>
                <div class="get-code-box" @click="getPhoneCode" v-else>
                    获取验证码
                </div>
            </div>
        </div>
        <div class="btn-box" @click="subForm">
           确 认
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    
    export default {
        components: {
            TopNav
        },
        props:{
          codecd:Number
        },
        data() {
            return {
                dataPack:{
                    inputPhone:null,
                    inputCode:null
                }
            }
        },
        methods: {
            getPhoneCode() {
                let requestData = {}
                requestData.code_type = 'sdk_bind_phone'
                requestData.inputPhone = this.dataPack.inputPhone
                this.$emit('getPhoneCode', requestData)
            },
            subForm() {
                let requestData = this.dataPack
                requestData.type = 'bindPhone'
                this.$emit('subForm', this.dataPack)
            }
        }
    }
</script>

<style scoped>
    .tips {
        padding-top: 6px;
        font-size: 8.75px;
        font-weight: 400;
        color: #999999;
    }
    .form-box {
        padding: 8.75px 0 6px 0;
    }
    .get-code-box {
        font-size: 8.75px;
        color: #16C5CE;
    }
    
    .get-code-cd {
        font-size: 8.75px;
        font-weight: 400;
        color: #666666;
    }
    .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 26.25px;
        background: #16C5CE;
        border-radius: 3.75px;
        font-size: 8.75px;
        font-weight: 400;
        color: #FFFFFF;
    }
</style>