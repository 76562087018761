<template>
    <div class="change-page">
        <TopNav title="换绑手机" backPath="Mine"></TopNav>
        <div v-if="isShowPhoneInput">
            <div class="form-box">
                <div class="input-box-v">
                    <input v-model="dataPack.inputPhone" @input="dataPack.inputPhone=dataPack.inputPhone.substr(0,11)" type="number" placeholder="请填写手机号" class="input-v">
                </div>
                <div class="input-box-v">
                    <input v-model="dataPack.inputNewPhoneCode" @input="dataPack.inputNewPhoneCode=dataPack.inputNewPhoneCode.substr(0,6)" type="number" placeholder="验证码5分钟内输入有效" class="input-v">
                    <div class="get-code-cd" v-if="codecd>0">
                        {{'重新发送('+ codecd+ ')'}}
                    </div>
                    <div class="get-code-box" @click="getPhoneCode('sdk_modify_bind_phone_confirm')" v-else>
                        获取验证码
                    </div>
                </div>
                <div class="tips-change">
                    请绑定手机号，如未继续绑定手机，绑定的手机号仍是之前的手机号
                </div>
            </div>
        </div>
        <div v-else>
            <div class="tips">
                当前绑定手机号：<span class="phone">{{$store.state.bindPhone}}</span>
            </div>
            <div class="form-box">
                <div class="input-box-v">
                    <input v-model="dataPack.inputCode" @input="dataPack.inputCode=dataPack.inputCode.substr(0,6)" type="number" placeholder="验证码5分钟内输入有效" class="input-v">
                    <div class="get-code-cd" v-if="codecd>0">
                        {{'重新发送('+ codecd+ ')'}}
                    </div>
                    <div class="get-code-box" @click="getPhoneCode('sdk_modify_bind_phone_validate')" v-else>
                        获取验证码
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-box" @click="subForm">
           确 认
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    export default {
        components: {
            TopNav
        },
        props:{
          codecd:Number,
          isShowPhoneInput:Boolean
        },
        data() {
            return {
                dataPack:{
                    inputPhone:null,
                    inputNewPhoneCode:null,
                    inputCode:null,
                },
            }
        },
        methods: {
            getPhoneCode(type) {
                let requestData = {}
                requestData.code_type = type
                requestData.inputPhone = this.$props.isShowPhoneInput?this.dataPack.inputPhone:this.$store.state.bindPhone
                this.$emit('getPhoneCode', requestData)
            },
            subForm(type) {
                let requestData = {}
                requestData.inputCode = this.$props.isShowPhoneInput?this.dataPack.inputNewPhoneCode:this.dataPack.inputCode
                requestData.type = this.$props.isShowPhoneInput?'bindNewPhone':'verifyOldPhone'
                requestData.inputPhone = this.$props.isShowPhoneInput?this.dataPack.inputPhone:this.$store.state.bindPhone
                this.$emit('subForm', requestData)
            }
        }
    }
</script>

<style scoped>
    .tips {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        text-align: center;
    }
    .phone {
        font-size: 16px;
        font-weight: 400;
        color: #5ECC71;
    }
    .form-box {
        padding: 14px 0 8px 0;
    }
    .get-code-box {
        font-size: 14px;
        color: #16C5CE;
    }
    
    .get-code-cd {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
    }
    .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 42px;
        background: #16C5CE;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
    }
    .tips-change {
        font-size: 11px;
        font-weight: 400;
        color: #666666;
    }
</style>