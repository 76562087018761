<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" @getPhoneCode="getPhoneCode"
            :isShowPhoneInput="isShowPhoneInput" :codecd="codecd" @subForm="subForm"></Horizontal>
        <Vertical ref="Vertical" v-else @getPhoneCode="getPhoneCode" :isShowPhoneInput="isShowPhoneInput"
            :codecd="codecd" @subForm="subForm"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/BindPhone/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/BindPhone/Vertical/Index.vue'

    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                codecd: 0,
                isCanSub: true,
                isShowPhoneInput: false, //是否显示修改手机时新手机号的输入框
                isTabBtn: 0
            }
        },
        methods: {
            getPhoneCode(data) {
                if (this.$common.timeFunc().timeStampS - this.isTabBtn < 7) {
                    this.$Toast({
                        message: '请勿频繁操作',
                    })
                    return
                } else {
                    this.isTabBtn = this.$common.timeFunc().timeStampS
                }
                if (!data.inputPhone) {
                    this.$Toast({
                        message: '请先输入手机号',
                    })
                    return
                }
                let that = this,
                    requestMethods = null,
                    requestData = {
                        code_type: data.code_type,
                        user_id: this.$store.state.userId,
                        token: this.$store.state.token
                    }
                switch (data.code_type) {
                    case 'sdk_bind_phone':
                        // console.log('绑定手机验证码')
                        requestMethods = 'getBindPhoneCode'
                        requestData.phone = data.inputPhone
                        break
                    case 'sdk_modify_bind_phone_validate':
                        // console.log('修改绑定手机旧手机验证')
                        requestMethods = 'changeBindGetOldPhoneCode'
                        break
                    case 'sdk_modify_bind_phone_confirm':
                        // console.log('修改绑定手机新手机确认短信')
                        requestMethods = 'changeBindGetNewPhoneCode'
                        requestData.phone = data.inputPhone
                        break
                }
                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
                    if (res.data.code === 200) {
                        this.codecd = 60
                        let cdTimer = setInterval(() => {
                            that.codecd = that.codecd - 1
                            if (that.codecd < 1) {
                                clearInterval(cdTimer)
                            }
                        }, 1000)
                        this.$Toast({
                            message: '验证码已发送',
                        })
                    }
                })
            },
            subForm(data) {
                if (!this.verifyParams(data)) {
                    return
                }
                this.isCanSub = false
                setTimeout(() => {
                    this.isCanSub = true
                }, 5000)
                let requestMethods = data.type,
                    requestData = {
                        user_id: this.$store.state.userId,
                        token: this.$store.state.token,
                        phone_code: data.inputCode
                    },
                    susessMsg = "成功"

                switch (data.type) {
                    case 'verifyOldPhone':
                        susessMsg = '验证成功！'
                        break
                    case 'bindNewPhone':
                        susessMsg = '换绑成功！'
                        requestData.phone = data.inputPhone
                        break
                    case 'bindPhone':
                        susessMsg = '绑定成功！'
                        requestData.phone = data.inputPhone
                        break
                }
                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
                    if (res.data.code === 200) {
                        if (data.type === 'verifyOldPhone') {
                            this.isShowPhoneInput = true
                            this.codecd = 0
                        } else if (data.type === 'bindNewPhone') {
                            switch (this.$common.getNowSystem()) {
                                case 'ios':
                                    this.$common.postMessageToIos('clearLoginInfoByChangeBind', null)
                                    break;
                                case 'Android':
                                    android.clearLoginInfoByChangeBind()
                                    break;
                                default:
                                    this.$common.postMessageToWindowParent('clearLoginInfoByChangeBind', null)
                                    break;
                            }
                            this.$common.afterBindPhone(requestData.phone)
                            setTimeout(() => {
                                this.$router.replace({
                                    name: 'MineIndex'
                                })
                            }, 2000)

                        } else {
                            this.$common.afterBindPhone(requestData.phone)
                            setTimeout(() => {
                                this.$router.replace({
                                    name: 'MineIndex'
                                })
                            }, 2000)
                        }
                        this.$Toast({
                            message: susessMsg,
                        })
                    }
                })
            },
            //校验参数格式
            verifyParams(data) {
                console.log(data)
                if (!data.inputPhone || !data.inputCode) {
                    console.log(data.inputPhone)
                    this.$Toast({
                        message: '手机号和验证码不能为空',
                    })
                    return false
                }
                if (data.inputPhone.length !== 11 || data.inputCode.length !== 6) {
                    this.$Toast({
                        message: '手机号或验证码格式有误',
                    })
                    return false
                }
                if (!this.isCanSub) {
                    this.$Toast({
                        message: '请勿频繁操作',
                    })
                    return false
                }
                return true
            }
        },
        beforeDestroy() {
            window.removeEventListener('message', this.listenerFunction)
        }
    }
</script>

<style>
</style>