<template>
    <div>
        <ChangeBind v-if="$store.state.bindPhoneStatus===1" :isShowPhoneInput="isShowPhoneInput" @subForm="subForm" @getPhoneCode="getPhoneCode" :codecd="codecd"></ChangeBind>
        <Bind v-else :codecd="codecd" @getPhoneCode="getPhoneCode" @subForm="subForm"></Bind>
    </div>
</template>

<script>
    import Bind from "@/components/Mine/BindPhone/Horizontal/Bind.vue"
    import ChangeBind from "@/components/Mine/BindPhone/Horizontal/ChangeBind.vue"
    
    export default {
        components: {
            Bind,
            ChangeBind
        },
        props:{
          codecd:Number,
          isShowPhoneInput:Boolean
        },
        data() {
            return {
              
            }
        },
        methods: {
            getPhoneCode(dataPack) {
                this.$emit('getPhoneCode', dataPack)
            },
            subForm(dataPack) {
                this.$emit('subForm', dataPack)
            }
        }
    }
</script>

<style scoped>
    

    

    
</style>
